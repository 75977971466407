import { Navigate } from 'react-router-dom';
import { useContext } from 'react';
import { AuthContext } from '../../contexts/auth';

function SecureA({ children }) {
  const { auth, role } = useContext(AuthContext);
  return auth && role === 'admin' ? children : <Navigate to="/admin/login" />;
}

function SecureC({ children }) {
  const { auth, role } = useContext(AuthContext);
  return auth && role === 'contratante' ? children : <Navigate to="/login" />;
}

function SecureB({ children }) {
  const { auth, role } = useContext(AuthContext);
  return auth && role === 'beneficiario' ? children : <Navigate to="/login" />;
}

function Secure({ children }) {
  const { auth } = useContext(AuthContext);
  return auth ? children : <Navigate to="/login" />;
}

export { SecureA, SecureB, SecureC, Secure };