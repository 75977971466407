import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Button, Container, CssBaseline, TextField, ThemeProvider, Typography, createTheme, Backdrop, CircularProgress } from '@mui/material';

import { AuthContext } from '../../../contexts/auth';
import UnimedLabel from '../../../components/labels/unimed';
import '../../../assets/fonts/fonts.css';

const HOST = process.env.REACT_APP_HOST;

export default function AdminLoginPage() {
  const { login, loading, toggleLoading } = React.useContext(AuthContext);
  const [user, setUser] = React.useState('');
  const [password, setPassword] = React.useState('');
  
  const navigate = useNavigate();

  const handleUserChange = (event) => {
    if (event.target.value.match(/^[a-zA-Z\s]*$/)) {
      setUser(event.target.value);
    }
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    toggleLoading(true);

    try {
      const response = await fetch(`https://${HOST}/api/authenticate/internal/admin`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ login: user, senha: password }),
      });

      toggleLoading(false);

      if (response.ok) {
        const data = await response.json();
        const token = data.token;
        const user = data.user;

        login(user, 'admin', token);

        navigate('/admin/search');

        setUser('');
        setPassword('');
      } else {
        alert('Login ou senha inválidos!');
      }
    } catch (error) {
      toggleLoading(false);
      console.error('Erro ao autenticar:', error);
      alert('Ocorreu um erro ao autenticar. Por favor, tente novamente.');
    }
  };

  const theme = createTheme({
    typography: {
      fontFamily: 'MyCustomFont, Arial',
    },
  });

  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '85vh',
          }}
        >
          <Box
            sx={{
              width: '100%',
              maxWidth: 400,
              boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.2)',
              borderRadius: 8,
              p: 3,
              justifyContent: 'center',
              textAlign: 'center',
            }}
          >
            <Box sx={{ display: 'flex', alignItems: 'center', mb: 1, mt: 1, flexDirection: 'column' }}>
              <Typography variant="h5" sx={{ color: 'rgba(0, 139, 80, 0.65)', mr: 1, mb: 2 }}>
                SGF - Administrador
              </Typography>
            </Box>
            <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
              <TextField
                margin="normal"
                required
                fullWidth
                id="user"
                label="Usuário"
                name="user"
                autoComplete="off"
                autoFocus
                value={user}
                onChange={handleUserChange}
              />
              <TextField
                margin="normal"
                required
                fullWidth
                name="password"
                label="Senha"
                type="password"
                id="password"
                autoComplete="current-password"
                value={password}
                onChange={handlePasswordChange}
              />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2, bgcolor: '#008b50', color: '#fafafa' }}
              >
                Entrar
              </Button>
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            position: 'fixed',
            bottom: 0,
            left: 0,
            width: '100%',
            height: '10%',
            bgcolor: '#008b50',
            color: '#fafafa',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            padding: '0 16px',
            zIndex: 1,
          }}
        >
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <UnimedLabel h={'24px'} w={'24px'} ml={'5px'}/>
          </Box>
          <Typography variant="h6" sx={{ mr: 2, textAlign:'end' }}>
            Região da <br/>Campanha/RS
          </Typography>
        </Box>
        <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={loading}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </Container>
    </ThemeProvider>
  );
}