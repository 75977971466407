import React, { useState } from 'react';
import { Button, TextField, Grid, Container, IconButton, InputAdornment, Backdrop, CircularProgress } from '@mui/material';
import axios from 'axios';
import SearchIcon from '@mui/icons-material/Search';
import UpdateIcon from '@mui/icons-material/Update';
import { AuthContext } from '../../../contexts/auth';

const HOST = process.env.REACT_APP_HOST;

function SearchBeneficiario() {
  const {loading, toggleLoading, token} = React.useContext(AuthContext);

  const [data, setData] = useState({
    cpf: '',
    nome: '',
    carteira: '',
    nascimento: '',
    e_mail: '',
    titular: '',
    telefone: '',
  });

  const formatCPF = cpf => {
    const cpfPattern = /(\d{0,3})(\d{0,3})(\d{0,3})(\d{0,2})/;
    return cpf.replace(cpfPattern, (match, p1, p2, p3, p4) => {
        let formattedValue = '';
        if (p1) formattedValue += p1;
        if (p2) formattedValue += '.' + p2;
        if (p3) formattedValue += '.' + p3;
        if (p4) formattedValue += '-' + p4;
        return formattedValue;
    });
  };

  const formatTelefone = telefone => {
      const telPattern = /(\d{0,2})(\d{0,1})(\d{0,8})/;
      return telefone.replace(telPattern, (match, p1, p2, p3) => {
          let formattedValue = '';
          if (p1) formattedValue += p1;
          if (p2) formattedValue += ' ' + p2;
          if (p3) formattedValue += ' ' + p3;
          return formattedValue;
      });
  };

  const formatCarteira = carteira => {
      const carteiraPattern = /(\d{0,1})(\d{0,3})(\d{0,12})(\d{0,1})/;
      return carteira.replace(carteiraPattern, (match, p1, p2, p3, p4) => {
          let formattedValue = '';
          if (p1) formattedValue += p1;
          if (p2) formattedValue += ' ' + p2;
          if (p3) formattedValue += ' ' + p3;
          if (p4) formattedValue += ' ' + p4;
          return formattedValue;
      });
  };

  const fetchAPI = async (field) => {
    toggleLoading(true);
    try {
      const apiUrl = `https://${HOST}/api/admin/beneficiario`;
      const response = await axios.get(apiUrl, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + token
        },
        params: {
          [field]: data[field].replace(/\s+/g, '')
        }
      });

      toggleLoading(false);
      if (response.status === 200) {
        const beneficiario = response.data.beneficiario;
        setData(prevData => ({
            ...prevData,
            cpf: formatCPF(beneficiario.cpf || prevData.cpf),
            carteira: formatCarteira(beneficiario.carteira || prevData.carteira),
            nome: beneficiario.nome,
            nascimento: beneficiario.nascimento,
            email: beneficiario.email,
            titular: beneficiario.is_titular ? 'TITULAR' : beneficiario.titular,
            e_mail: beneficiario.e_mail,
            telefone: formatTelefone(beneficiario.telefone)
        }));
      } else {
        alert('Erro ao buscar dados');
      }
    } catch (error) {
      toggleLoading(false);
      alert('Erro ao buscar dados');
      console.error(`Erro ao buscar dados pelo ${field}`, error);
    }
  };

  const updateData = async () => {
    toggleLoading(true);
    try {
      const apiUrl = `https://${HOST}/api/admin/beneficiario`;

      const dataSend = {
        cpf: data.cpf.replace(/\s+/g, ''),
        telefone: data.telefone.replace(/\s+/g, ''),
        e_mail: data.e_mail
      };

      const response = await axios.put(apiUrl, dataSend, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + token
        }
      });
  
      toggleLoading(false);
      if (response.status === 200) {
        alert('Dados atualizados com sucesso!');
      } else {
        alert('Erro ao atualizar dados');
      }
    } catch (error) {
      toggleLoading(false);
      alert('Erro ao atualizar dados');
      console.error("Erro ao atualizar os dados do beneficiário", error);
    }
  };
  
  const handleChange = (e) => {
    let { name, value } = e.target;

    if (name !== "e_mail") {
      value = value.replace(/\D/g, '');
    }

    if (name === "cpf") {
      value = formatCPF(value);
      if (value.length > 14) {
          value = value.slice(0, 14);
      }
  } else if (name === "telefone") {
      value = formatTelefone(value);
      if (value.length > 13) {
          value = value.slice(0, 13);
      }
  } else if (name === "carteira") {
      value = formatCarteira(value);
      if (value.length > 20) {
          value = value.slice(0, 20);
      }
  }

    setData(prevData => ({
      ...prevData,
      [name]: value
    }));
  };

  const renderTextField = (label, name, isEditable = false) => (
    <TextField
      fullWidth
      label={label}
      name={name}
      value={data[name]}
      onChange={handleChange}
      InputProps={{ readOnly: !isEditable }}
    />
  );

  return (
    <Container style={{ height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
      <Grid container spacing={3} alignItems="center" justifyContent="center">
        {['cpf', 'carteira'].map((field, index) => (
          <Grid key={index} item xs={6}>
            <TextField
              fullWidth
              label={field === 'cpf' ? 'CPF' : 'Carteira'}
              name={field}
              value={data[field]}
              onChange={handleChange}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={() => fetchAPI(field)}>
                      <SearchIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
        ))}
        <Grid item xs={6}>{renderTextField("Nome", "nome")}</Grid>
        <Grid item xs={6}>{renderTextField("Telefone", "telefone", true)}</Grid>
        <Grid item xs={4}>{renderTextField("E-Mail", "e_mail", true)}</Grid>
        <Grid item xs={4}>{renderTextField("Nascimento", "nascimento")}</Grid>
        <Grid item xs={4}>{renderTextField("Titular", "titular")}</Grid>
        <Grid item xs={12} container justifyContent="center">
          <Button 
            onClick={updateData} 
            endIcon={<UpdateIcon />}
            sx={{ bgcolor: '#008b50', color: '#fafafa', marginTop: 2,         
            '&:hover': {
              bgcolor: '#006b40' 
          } }}
           disabled={data.cpf.length < 14 || data.telefone.length < 13}
          >
            Atualizar
          </Button>
        </Grid>
      </Grid>
      <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={loading}>
          <CircularProgress color="inherit" />
      </Backdrop>
    </Container>
  );
}

export default SearchBeneficiario;