// src/components/TwoFactorAuthPage.js
import React, { useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import './index.css';
import { Backdrop, Box, ButtonBase, CircularProgress, FormControl, Grid, IconButton, InputAdornment, InputLabel, OutlinedInput, Typography } from '@mui/material';

import { AuthContext } from '../../contexts/auth';
import { Visibility, VisibilityOff } from '@mui/icons-material';

const HOST = process.env.REACT_APP_HOST;

const passwordRequirements = [
  { label: 'Pelo menos 8 caracteres', isValid: (password) => password.length >= 8 },
  { label: 'Pelo menos 1 letra maiúscula', isValid: (password) => /[A-Z]/.test(password) },
  { label: 'Pelo menos 1 letra minúscula', isValid: (password) => /[a-z]/.test(password) },
  { label: 'Pelo menos 1 número', isValid: (password) => /\d/.test(password) },
  { label: 'Pelo menos 1 caractere especial', isValid: (password) => /[!@#$%^&*()_+{}[\]:;<>,.?~\\/-]/.test(password) }
];

const PassTwoFactorAuthPage = () => {
  const { login, logout, loading, toggleLoading, setAuth } = React.useContext(AuthContext);
  const [showPassword, setShowPassword] = useState(false);
  const [password, setPassword] = useState('');
  const [showPassword1, setShowPassword1] = useState(false);
  const [password1, setPassword1] = useState('');
  const [code, setCode] = useState(['', '', '', '', '', '']);
  const [count, setCount] = useState(0);
  const [phone, setPhone] = useState(localStorage.getItem('tel') || '');
  const codeInputs = useRef([]);
  const errorMessage = 'Erro na validação do código de autenticação.';
  const [countdown, setCountdown] = useState(300); 

  React.useEffect(() => {
    if (countdown > 0) {
      const timer = setInterval(() => {
        setCountdown((prevCountdown) => prevCountdown - 1);
      }, 1000);

      return () => {
        clearInterval(timer);
      };
    }
  }, [countdown]);

  const navigate = useNavigate();

  const handleChange = (index, value) => {
    const newCode = [...code];
    newCode[index] = value;
    setCode(newCode);

    if (index < codeInputs.current.length - 1 && value !== '') {
      codeInputs.current[index + 1].focus();
    }
  };

  const handleBackspace = (index, e) => {
    if (e.key === 'Backspace' && index > 0 && code[index] === '') {
      codeInputs.current[index - 1].focus();
    }
  };

  const handleSubmit = async () => {
    const codeString = code.join('');

    toggleLoading(true);

    try {
      fetch(`https://${HOST}/api/authenticate/2fa`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ code: codeString, 
                              cnpj: localStorage.getItem('cnpj'), 
                              carteira: localStorage.getItem('carteira'),
                              senha: password
        }),
      })
      .then(
        (res) => res.json()
      )
      .then((data) => {
        toggleLoading(false);
        if (data.exec === 'OK') {
          login(data.user, data.role, data.token);
          navigate('/');
        }
        else {
          if ( count >= 4 ) {
            toggleLoading(true);
            fetch(`https://${HOST}/api/authenticate/remove/2fa`, {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
              },
              body: JSON.stringify({ cnpj: localStorage.getItem('cnpj'),
                                     carteira: localStorage.getItem('carteira')
              }),
            })
            .then((res) => res.json())
            .then((data) => {
              toggleLoading(false);
              if (data.exec === 'OK') {
                login(data.user, data.role, data.token);
                navigate('/');
              }
            })
            logout();
            alert('Número de tentativas excedido.');
            setAuth(false);
            navigate('/login');
            return;
          }
          setCode(['', '', '', '', '', '']);
          setCount(counter => counter + 1);
        }
      });
    } catch (err) {
      toggleLoading(false);
      setAuth(false);
      alert(errorMessage);
    }
  };

  const handleTogglePassword = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  const handleTogglePassword1 = () => {
    setShowPassword1((prevShowPassword) => !prevShowPassword);
  };

  const handlePasswordChange = (event) => {
    const newPassword = event.target.value;
    setPassword(newPassword);
  };

  const handlePassword1Change = (event) => {
    const newPassword = event.target.value;
    setPassword1(newPassword);
  };

  const comparePasswords = () => password === password1;

  const areAllFieldsFilled = () => password !== '' && password1 !== '' && comparePasswords(); 

  const isPasswordValid = () => passwordRequirements.every((requirement) => requirement.isValid(password)) && comparePasswords();

  return (
    <div className="two-factor-auth">
      <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" height="100vh" width="80%" margin="0 auto">
      <h2>ATUALIZAÇÃO DE SENHA</h2>
      <p style={{textAlign:'center'}}>
        <br/>
        Se você não receber o código em até {Math.floor(countdown / 60)}:{(countdown % 60) < 10 ? '0'+(countdown % 60) : (countdown % 60)},
      entre em contato com a UNIMED. 
        <br/><br/>
        O código tem validade de 2 horas, assim, se você fez o tentou fazer login e não finalizou, utilize o enviado anteriormente.
        <br/><br/>
        Insira o código de autenticação enviado para o seu telefone {phone}
      </p>
      <div className="code-input">
        {code.map((digit, index) => (
          <input
            key={index}
            ref={(el) => (codeInputs.current[index] = el)}
            type="text"
            maxLength={1}
            value={digit}
            onChange={(e) => handleChange(index, e.target.value)}
            onKeyDown={(e) => handleBackspace(index, e)}
          />
        ))}
      </div>
      <FormControl variant="outlined" fullWidth sx={{ marginBottom: 2 }}>
        <InputLabel htmlFor="Nova">Nova Senha</InputLabel>
        <OutlinedInput
          id="Nova"
          type={showPassword ? 'text' : 'password'}
          value={password}
          onChange={handlePasswordChange}
          endAdornment={
            <InputAdornment position="end">
              <IconButton onClick={handleTogglePassword} edge="end">
                {showPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          }
          label="Nova Senha"
          inputProps={{ maxLength: 16 }}
        />
        <Grid container alignItems="flex-start" justifyContent="flex-start" mt={1}>
          <Typography variant="caption">Requisitos da senha:</Typography>
          {passwordRequirements.map((requirement, index) => (
            <Grid item xs={12} key={index}>
              <Typography
                variant="caption"
                color={requirement.isValid(password) ? 'textSecondary' : 'error'}
                sx={{ display: 'block', marginLeft: '8px' }}
              >
                {requirement.isValid(password) ? '✓' : '✗'} {requirement.label}
              </Typography>
            </Grid>
          ))}
        </Grid>
      </FormControl>
      <FormControl variant="outlined" fullWidth sx={{ marginBottom: 2 }}>
        <InputLabel htmlFor="Validacao">Repita a senha</InputLabel>
        <OutlinedInput
          id="Validacao"
          type={showPassword1 ? 'text' : 'password'}
          value={password1}
          onChange={handlePassword1Change}
          endAdornment={
            <InputAdornment position="end">
              <IconButton onClick={handleTogglePassword1} edge="end">
                {showPassword1 ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          }
          label="Repita a senha"
          inputProps={{ maxLength: 16 }}
        />
      </FormControl>
      {!comparePasswords() && (
        <Typography variant="caption" color="error">
          As senhas não são as mesmas.
        </Typography>
      )}
      <ButtonBase
        disabled={!isPasswordValid() || !areAllFieldsFilled()}
        sx={{
          display: 'block',
          margin: '10px auto',
          backgroundColor: isPasswordValid() && areAllFieldsFilled() ? '#008b50' : 'grey',
          color: '#fafafa',
          padding: '10px 20px',
          borderRadius: '5px',
          '&:hover': {
            backgroundColor: isPasswordValid() && areAllFieldsFilled() ? '#009439' : 'grey',
            opacity: 1,
          },
        }}
        onClick={handleSubmit}
      >
        Atualizar Senha
      </ButtonBase>
      <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </Box>
    </div>
  );
};

export default PassTwoFactorAuthPage;
