import React from 'react'
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import { Button } from '@mui/material';

export default function SearchButton({ onSearchClick }) {
  return (
    <Button className='logoutButton' 
    variant="outlined" 
    startIcon={<SearchOutlinedIcon />} 
    color='inherit'
    style={{textTransform: 'none', justifyContent: 'center', alignItems: 'center', width: '40%'}}
    onClick={onSearchClick}
    >
        Buscar
    </Button>
  )
}