import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import RefreshIcon from '@mui/icons-material/Refresh';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { Backdrop, CircularProgress } from '@mui/material';

import './index.css';

import { AuthContext } from '../../../contexts/auth';

const HOST = process.env.REACT_APP_HOST;

export default function TokenManager() {
    const [tokens, setTokens] = useState([]);
    const { token, loading, toggleLoading, open, auth } = useContext(AuthContext);

    useEffect(() => {
      fetchTokens();
    }, []);

    const fetchTokens = async () => {
      toggleLoading(true);
      try {
        const apiUrl = `https://${HOST}/api/admin/tokens-ativos`;
        const response = await axios.get(apiUrl, {
            headers: { 
              'Content-Type': 'application/json',
              'Authorization': 'Bearer ' + token
            }
          }
        );
        setTokens(response.data);
        toggleLoading(false);
      } catch (error) {
        toggleLoading(false);
        console.error('Erro ao buscar os tokens:', error);
      }
    };
    
    const deleteToken = async (document) => {
      toggleLoading(true);
      try {
        const apiUrl = `https://${HOST}/api/admin/token`;
        const response = await axios.delete(apiUrl, {
          data: { document },
          headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
          }
        });
        setTokens(response.data);
        toggleLoading(false);
      } catch (error) {
        toggleLoading(false);
        console.error('Erro ao deletar o token:', error);
      }
    };
  
  return (
    <div className={`${auth ? (!open ? 'autorizado' : 'sidebarOpen') : 'token-manager'}`}>
      <div className="header">
        <h2>Manutenção de <br/>Códigos Ativos</h2>
        <IconButton onClick={fetchTokens}>
          <RefreshIcon />
        </IconButton>
      </div>
      <div className="card-list">
        {tokens.map(token => (
          <Card key={token.time} className="token-card">
            <div className="card-content-wrapper">
              <CardContent className="card-content">
                <Typography variant="h6" component="div" className="document-text">
                  {token.document}
                </Typography>
                <Typography color="textSecondary" className="date-text">
                  {new Date(token.time).toLocaleString()}
                </Typography>
              </CardContent>
              <div className="delete-button-wrapper">
                <IconButton onClick={() => deleteToken(token.document)} className="delete-button">
                  <DeleteIcon />
                </IconButton>
              </div>
            </div>
          </Card>
        ))}
      </div>
      <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={loading}>
          <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  );
}