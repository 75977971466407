import React from 'react'
import { Accordion, AccordionDetails, AccordionSummary, Backdrop, CircularProgress, Divider, Stack, Typography } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import axios from 'axios';

import { AuthContext } from '../../../contexts/auth';
import DownloadButton from '../../buttons/download';

const HOST = process.env.REACT_APP_HOST;

export default function ReportAccordion({ demonstrativos }) {
    const { user, token, logout, loading, toggleLoading } = React.useContext(AuthContext);

    const handleDownload = async (demonstrativo) => {
        toggleLoading(true);
        try {
            const response = await axios.post(`https://${HOST}/api/v1/dem/download`,
                { demonstrativo },
                {
                    responseType: 'blob',
                    headers: {
                        Authorization: 'Bearer ' + token,
                    },
                });

            if (response.data.message && response.data.message === 'Token provided is invalid') {
                alert('Sessão expirada, faça login novamente');
                logout();
                window.location.reload();
                return;
            }

            const downloadUrl = URL.createObjectURL(new Blob([response.data]));

            const link = document.createElement('a');
            link.href = downloadUrl;
            link.download = `DEMONSTRATIVO ${demonstrativo.periodo} - ${user}.pdf`;
            link.click();

            URL.revokeObjectURL(downloadUrl);
        } catch (error) {
            toggleLoading(false);
            console.error('Error downloading the invoice:', error);
        }
        toggleLoading(false);
    };


    return (
        Object.keys(demonstrativos).length === 0 ?
            <Typography sx={{ width: '100%', textAlign:'center'}}>
                <b> Nenhum demonstrativo encontrado </b>
            </Typography>
        :
        Object.keys(demonstrativos).map((key, index) => {
            const demonstrativo = demonstrativos[key];
            return (
                <Accordion key={index} sx={{width: '80%'}}>
                    <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    >
                        <Typography sx={{ width: '100%', textAlign:'start', marginLeft:'2%'}}>
                            <b> {demonstrativo.contratante} </b>
                        </Typography>
                    </AccordionSummary>
                    <Divider sx={{ width: '90%', marginRight:'5%', marginBottom:'5px' }} variant="middle" />
                    <AccordionDetails>
                        <Stack direction="row" sx={{ width: '80%', textAlign:'start', marginLeft:'5%', justifyContent:'space-between'}}>
                            <Typography>
                                <b> Competência </b> &nbsp;&nbsp;: &nbsp; {demonstrativo.periodo} <br/>
                                <b> Mensalidade </b> &nbsp;&nbsp;: &nbsp; R$ {demonstrativo.total_mensalidades.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })} <br/>
                                <b> Participações </b> &nbsp;: &nbsp; R$ {demonstrativo.total_participacoes.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })} <br/>
                                <b> Total Geral </b> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;: &nbsp; R$ {(demonstrativo.total_mensalidades + demonstrativo.total_participacoes).toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })} <br/>
                            </Typography>
                            <DownloadButton 
                                onClick={() => handleDownload(demonstrativo)}
                                label={'DDEM'}
                            />
                        </Stack>
                    </AccordionDetails>
                <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={loading}
                >
                    <CircularProgress color="inherit" />
                </Backdrop>
                </Accordion>
            );
        })
    );
}