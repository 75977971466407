import React, { createContext, useState } from 'react';

const AuthContext = createContext();

const AuthProvider = ({ children }) => {
    const [user, setUser] = useState(null);
    const [auth, setAuth] = useState(false);
    const [role, setRole] = useState(null);
    const [token, setToken] = useState(null);
    const [open, setOpen] = useState(true);
    const [loading, setLoading] = useState(false);

    React.useEffect(() => {
        const storedUser = localStorage.getItem('user');
        const storedRole = localStorage.getItem('role');
        const storedToken = localStorage.getItem('token');
        const storedTime = localStorage.getItem('time');

        const currentTime = new Date().getTime();

        if ((currentTime - storedTime) < 3600000) {
            if (storedUser && storedRole && storedToken && storedTime) {
                setUser(storedUser);
                setRole(storedRole);
                setToken(storedToken);
                setAuth(true);
            }
        } else {
            logout();
        }
    }, []);
    
    const login = (user, role, token) => {
        localStorage.setItem('user', user);
        localStorage.setItem('role', role);
        localStorage.setItem('token', token);
        localStorage.setItem('time', new Date().getTime());

        setUser(user);
        setRole(role);
        setAuth(true);
        setToken(token);
    }

    const logout = () => {
        localStorage.removeItem('user');
        localStorage.removeItem('role');
        localStorage.removeItem('token');
        localStorage.removeItem('time');
        localStorage.removeItem('cnpj');
        localStorage.removeItem('carteira');
        setUser(null);
        setRole(null);
        setAuth(false);
        setToken(null);
    }

    const toggleSidebar = () => {
        setOpen(!open);
    }

    const toggleLoading = (status) => {
        setLoading(status);
    }

    const data = { user, auth, role, token, open, loading, login, logout, toggleSidebar, toggleLoading, setAuth };

    return (
        <AuthContext.Provider value={data}>
            {children}
        </AuthContext.Provider>
    );
};

export { AuthContext, AuthProvider };