import React, { useContext } from 'react'
import Button from '@mui/material/Button';
import LogoutIcon from '@mui/icons-material/Logout';

import { AuthContext } from '../../../contexts/auth';
import './index.css';

export default function LogoutButton() {
  const { logout } = useContext(AuthContext);
  return (
    <Button className='logoutButton' variant="contained" startIcon={<LogoutIcon />} onClick={logout}>
        Sair
    </Button>
  )
}