import React from 'react'
import { Backdrop, CircularProgress, FormControl, InputLabel, MenuItem, Select, Stack } from '@mui/material';

import { AuthContext } from '../../../contexts/auth';
import SearchButton from '../../../components/buttons/search';
import ReportAccordion from '../../../components/displays/reportAccordion';

const HOST = process.env.REACT_APP_HOST;

export default function ReportPage() {
  const [mes, setMonth] = React.useState(1);
  const [ano, setYear] = React.useState(2023);
  const [dems, setDemonstrativos] = React.useState([]);
  const { token, logout, loading, toggleLoading } = React.useContext(AuthContext);

  const handleChangeMonth = (event) => {
    setMonth(event.target.value);
  };
  
  const handleChangeYear = (event) => {
    setYear(event.target.value);
  };

  const handleSearch = () => {
    toggleLoading(true);
    const competencia = `${mes}-${ano}`;

    fetch(`https://${HOST}/api/v1/dem/` + competencia, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token,
      }
    })
    .then(response => {
      toggleLoading(false);
      if (!response.ok) {
        setDemonstrativos([]);
        throw new Error('Network response was not ok');
      }
      return response.json();
    })
    .then(data => {
      if(data.message && data.message === 'Token provided is invalid') {
        alert('Sessão expirada, faça login novamente');
        logout();
        window.location.reload();
        return;
      }
      setDemonstrativos(data);
    })
    .catch(error => {
      toggleLoading(false);
      console.error('Erro ao buscar as faturas:', error);
    });
  };

  return (
    <div style={{marginLeft:'0px', marginTop:'50px'}}>
      <div style={{display:'flex', alignItems:'center', flexDirection:'column', gap:'10px'}}>
      <Stack direction="column" spacing={1} width={'80%'} alignItems={'center'}>
        <Stack direction="row" spacing={1} alignItems={'center'} justifyContent={'center'}>
            <div style={{gap:'2px'}}>
                <FormControl sx={{ m: 1, minWidth: 120}}>
                    <InputLabel>Mês</InputLabel>
                    <Select
                    value={mes}
                    label="Mês"
                    onChange={handleChangeMonth}
                    >
                    <MenuItem value={1}>Janeiro</MenuItem>
                    <MenuItem value={2}>Fevereiro</MenuItem>
                    <MenuItem value={3}>Março</MenuItem>
                    <MenuItem value={4}>Abril</MenuItem>
                    <MenuItem value={5}>Maio</MenuItem>
                    <MenuItem value={6}>Junho</MenuItem>
                    <MenuItem value={7}>Julho</MenuItem>
                    <MenuItem value={8}>Agosto</MenuItem>
                    <MenuItem value={9}>Setembro</MenuItem>
                    <MenuItem value={10}>Outubro</MenuItem>
                    <MenuItem value={11}>Novembro</MenuItem>
                    <MenuItem value={12}>Dezembro</MenuItem>
                    </Select>
                </FormControl>
                <FormControl sx={{ m: 1, minWidth: 120 }}>
                    <InputLabel>Ano</InputLabel>
                    <Select
                    value={ano}
                    label="Ano"
                    onChange={handleChangeYear}
                    >
                    <MenuItem value={2021}>2021</MenuItem>
                    <MenuItem value={2022}>2022</MenuItem>
                    <MenuItem value={2023}>2023</MenuItem>
                    <MenuItem value={2024}>2024</MenuItem>
                    <MenuItem value={2025}>2025</MenuItem>
                    </Select>
                </FormControl>
            </div>
          <SearchButton onSearchClick={handleSearch}/>
        </Stack>
        <ReportAccordion demonstrativos={dems}/>
      </Stack>
      </div>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  )
}