import * as React from 'react';
import { styled } from '@mui/material/styles';
import MuiDrawer from '@mui/material/Drawer';
import { Avatar, Box, CssBaseline, IconButton, Stack } from '@mui/material';
import ArrowLeft from '@mui/icons-material/KeyboardDoubleArrowLeft';
import ArrowRight from '@mui/icons-material/KeyboardDoubleArrowRight';
import Description from '@mui/icons-material/DescriptionOutlined';
import LogoutIcon from '@mui/icons-material/Logout';
import InboxOutlinedIcon from '@mui/icons-material/InboxOutlined';
import PhoneOutlinedIcon from '@mui/icons-material/PhoneOutlined';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import ManageSearchOutlinedIcon from '@mui/icons-material/ManageSearchOutlined';
import EngineeringOutlinedIcon from '@mui/icons-material/EngineeringOutlined';
import KeyOutlinedIcon from '@mui/icons-material/KeyOutlined';

import { AuthContext } from '../../contexts/auth';
import LogoutButton from '../buttons/logout';
import UnimedLabel from '../labels/unimed';
import ListLinks from './list';
import UserIdentity from '../identifiers/user';

const drawerWidth = 240;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  border:'none',
  color:'#FAFAFA',
  backgroundColor:'#008B50',
  overflowX: 'hidden',
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  border:'none',
  color:'#FAFAFA',
  backgroundColor:'#008B50',
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
    }),
  }),
);

export default function Sidebar() {
    const { user, role, logout, open, toggleSidebar } = React.useContext(AuthContext);

    const itensContratante = [
      {name: 'Início', icon: <HomeOutlinedIcon />, link: '/'},
      {name: 'Faturas', icon: <InboxOutlinedIcon />, link: '/invoice'},
      {name: 'Contato', icon: <PhoneOutlinedIcon />, link: '/contact'}
    ]

    const itensBeneficiario = [
      {name: 'Início', icon: <HomeOutlinedIcon />, link: '/'},
      {name: 'Demonstrativos', icon: <Description />, link: '/report'},
      {name: 'Contato', icon: <PhoneOutlinedIcon />, link: '/contact'}
    ]

    const itensAdmin = [
      {name: 'Busca', icon: <ManageSearchOutlinedIcon />, link: '/admin/search'},
      {name: 'Manutenção', icon: <EngineeringOutlinedIcon />, link: '/admin/maintenance'},
      {name: 'Tokens', icon: <KeyOutlinedIcon />, link: '/admin/token-manager'}
    ]


    const handleLogout = () => {
      logout();
      window.location.reload();
    }

    return (
      <Box sx={{ display: 'flex' }}>
        <CssBaseline />
        <Drawer variant="permanent" open={open} sx={{display: 'flex', flexDirection:'column'}}>
          <DrawerHeader sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', margin: '5px' }}>
            {open ? <UnimedLabel h={'24px'} w={'24px'} ml={'5px'}/> : ''}
            <IconButton onClick={toggleSidebar} sx={{ color: '#FAFAFA' }}> {open ? <ArrowLeft /> : <ArrowRight />} </IconButton>
          </DrawerHeader>
          <ListLinks items={role !== 'admin' ? (role === 'contratante' ? itensContratante : itensBeneficiario) : itensAdmin} open={open} />
          <Stack direction="column" spacing={1} sx={{margin: '10px', allignContent:'start'}}>
            {open ? <UserIdentity text={user} /> : <Avatar sx={{bgcolor: '#448aff'}}> {user[0]} </Avatar>}
            {open ? <LogoutButton /> : <IconButton onClick={handleLogout} sx={{color:'#FAFAFA', justifyContent:'center'}}> <LogoutIcon /> </IconButton>}
          </Stack>
        </Drawer>
        <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
          <DrawerHeader />
        </Box>
      </Box>
    );
};