// src/components/TwoFactorAuthPage.js
import React, { useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import './index.css';
import { Backdrop, CircularProgress } from '@mui/material';

import { AuthContext } from '../../contexts/auth';

const HOST = process.env.REACT_APP_HOST;

const TwoFactorAuthPage = () => {
  const { login, logout, loading, toggleLoading, setAuth } = React.useContext(AuthContext);
  const [code, setCode] = useState(['', '', '', '', '', '']);
  const [count, setCount] = useState(0);
  const codeInputs = useRef([]);
  const [phone, setPhone] = useState(localStorage.getItem('tel') || '');
  const errorMessage = 'Erro na validação do código de autenticação.';
  const [countdown, setCountdown] = useState(300); 

  React.useEffect(() => {
    if (countdown > 0) {
      const timer = setInterval(() => {
        setCountdown((prevCountdown) => prevCountdown - 1);
      }, 1000);

      return () => {
        clearInterval(timer);
      };
    }
  }, [countdown]);

  const navigate = useNavigate();

  const handleChange = (index, value) => {
    const newCode = [...code];
    newCode[index] = value;
    setCode(newCode);

    if (index < codeInputs.current.length - 1 && value !== '') {
      codeInputs.current[index + 1].focus();
    }
  };

  const handleBackspace = (index, e) => {
    if (e.key === 'Backspace' && index > 0 && code[index] === '') {
      codeInputs.current[index - 1].focus();
    }
  };

  const handleValidation = async () => {
    const codeString = code.join('');

    toggleLoading(true);

    try {
      fetch(`https://${HOST}/api/authenticate/2fa`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ code: codeString, 
                              cnpj: localStorage.getItem('cnpj'), 
                              carteira: localStorage.getItem('carteira')
        }),
      })
      .then(
        (res) => res.json()
      )
      .then((data) => {
        toggleLoading(false);
        console.log(data);
        if (data.exec === 'OK') {
          login(data.user, data.role, data.token);
          navigate('/');
        }
        else {
          if ( count >= 4 ) {
            toggleLoading(true);
            fetch(`https://${HOST}/api/authenticate/remove/2fa`, {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
              },
              body: JSON.stringify({ cnpj: localStorage.getItem('cnpj'),
                                     carteira: localStorage.getItem('carteira')
              }),
            })
            .then((res) => res.json())
            .then((data) => {
              toggleLoading(false);
              console.log(data);
              if (data.exec === 'OK') {
                login(data.user, data.role, data.token);
                navigate('/');
              }
            })
            logout();
            alert('Número de tentativas excedido.');
            setAuth(false);
            navigate('/login');
            return;
          }
          setCode(['', '', '', '', '', '']);
          setCount(counter => counter + 1);
        }
      });
    } catch (err) {
      toggleLoading(false);
      setAuth(false);
      alert(errorMessage);
    }
  };

  return (
    <div className="two-factor-auth">
      <h2>AUTENTICAÇÃO DE DOIS FATORES</h2>
      <p style={{textAlign:'center'}}>Insira o código de autenticação enviado para o seu telefone {phone}.
        <br/>
        Se você não receber o código em até {Math.floor(countdown / 60)}:{(countdown % 60) < 10 ? '0'+(countdown % 60) : (countdown % 60)} minutos,
      entre em contato com a UNIMED.<br/> 
        O código tem validade de 2 horas, assim, se você fez o processo de login e não utilizou o anterior, utilize o enviado anteriormente.
      </p>
      <div className="code-input">
        {code.map((digit, index) => (
          <input
            key={index}
            ref={(el) => (codeInputs.current[index] = el)}
            type="text"
            maxLength={1}
            value={digit}
            onChange={(e) => handleChange(index, e.target.value)}
            onKeyDown={(e) => handleBackspace(index, e)}
          />
        ))}
      </div>
      <button onClick={handleValidation}>Validar</button>
      <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  );
};

export default TwoFactorAuthPage;
