import React from 'react';
import { Card, CardContent, Typography, Box, CardMedia, useTheme, useMediaQuery } from "@mui/material";

import UnimedFachada from "../../../assets/images/unimed_fachada.jpeg";
import Mapa from "../../../assets/images/mapa.png";

export default function ContactPage() {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));

  const boxStyle = isSmallScreen ? 
  {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '1rem',
    height: 'fit-content',
    padding: '0.4rem',
    paddingLeft: '0rem',
    overflow: 'auto'
  } : 
  {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '1rem',
    height: '90vh',
    padding: '2rem',
    paddingLeft: '0rem',
    overflow: 'auto'
  };

  const boxInnerStyle = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '70%',
    maxWidth: '1000px',
    flexWrap: 'wrap',
    gap: '1rem',
  }

  return (
    <Box sx={boxStyle}>
      <Box sx={boxInnerStyle}>
        <Card sx={{ maxWidth: 300, maxHeight: 450, backgroundColor: '#008b50', color: '#fafafa' }}>
          <CardMedia
            component="img"
            height="200"
            image={Mapa}
            alt="Mapa da Unimed Região da Campanha - Sede Administrativa"
            sx={{
              objectFit: 'cover',
              imageResolution: '1200dpi',
              objectPosition: '100%'
            }}
            onClick={() => window.open('https://goo.gl/maps/pkTV5n8AGhKohtxVA', '_blank')}
            style={{ cursor: 'pointer' }}
          />
          <CardContent onClick={() => window.open('https://goo.gl/maps/pkTV5n8AGhKohtxVA', '_blank')} style={{ cursor: 'pointer' }}>
            <Typography gutterBottom variant="h5" component="div" textAlign={'center'}>
              <b>Unimed Região da Campanha - Sede Administrativa</b>
            </Typography>
            <Typography variant="body2" color="#fafafa" textAlign={'center'}>
              Av. Sete de Setembro 679 - Centro <br /> Bagé - RS | 96400-006
            </Typography>
          </CardContent>
        </Card>
        <Card sx={{ maxWidth: 300, maxHeight: 450, backgroundColor: '#008b50', color: '#fafafa' }}>
          <CardMedia
              component="img"
              height="200"
              image={UnimedFachada}
              alt="Fachada Unimed Região da Campanha - Sede Administrativa"
              sx={{
                objectFit: 'cover',
                objectPosition: '100% 85%',
              }}
              onClick={() => window.open('https://www.unimed.coop.br/site/web/regiaodacampanha/home', '_blank')}
              style={{ cursor: 'pointer' }}
            />
            <CardContent onClick={() => window.open('https://www.unimed.coop.br/site/web/regiaodacampanha/home', '_blank')} style={{ cursor: 'pointer' }}>
              <Typography gutterBottom variant="h5" component="div" textAlign={'center'}>
                <b>Horários de Atendimento</b>
              </Typography>
              <Typography variant="body2" color="#fafafa" textAlign={'center'}>
                Funcionamento de Segunda a Sexta <br />
                Manhã: 08h00 às 11h45 <br />
                Tarde: &nbsp; 14h00 às 18h00
              </Typography>
            </CardContent>
        </Card>
      </Box>
      <Card sx={{ maxWidth: 300, maxHeight: 450, backgroundColor: '#008b50', color: '#fafafa' }}>
          <CardContent>
            <Typography gutterBottom variant="h5" component="div" textAlign={'center'}>
              <b>Contato</b>
            </Typography>
            <Typography variant="body2" color="#fafafa" textAlign={'center'}>
              Telefone: (53) 3242-8372
            </Typography>
            <Typography variant="body2" color="#fafafa" textAlign={'center'}>
              Suporte: faturamento@unimedcampanha.com.br
            </Typography>
          </CardContent>
      </Card>
    </Box>
  );
}
