import React from 'react';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import RequestQuoteOutlined from '@mui/icons-material/RequestQuoteOutlined';
import DescriptionOutlined from '@mui/icons-material/DescriptionOutlined';
import IconButton from '@mui/material/IconButton';

export default function DownloadButton({ onClick, label }) {
  let IconComponent;

  switch (label) {
    case 'DFAT':
      IconComponent = RequestQuoteOutlined;
      break;
    case 'DDEM':
      IconComponent = DescriptionOutlined;
      break;
    default:
      IconComponent = FileDownloadOutlinedIcon;
  }

  return (
    <IconButton aria-label="download" onClick={onClick}>
      <IconComponent />
    </IconButton>
  );
}