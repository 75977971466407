import React from 'react'
import { Typography } from '@mui/material'

import Pinheiro from '../../../assets/images/pinheiro.png';

export default function UnimedLabel({h, w, ml, color='#FAFAFA'}) {
  return (
    <Typography variant="h6" noWrap sx={{color:color}}>
        <div style={{display:'flex', flexDirection:'row', justifyContent:'center', alignItems:'center'}}>
            Unimed
            <img src={Pinheiro} alt="Pinheiro" style={{width:w, height:h, marginLeft:ml}}/>
        </div>
    </Typography>
  )
}
