import React from 'react';
import { List, ListItem, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import { Link } from 'react-router-dom';

export default function ListLinks({ items, open }) {
  return (
    <List sx={{ height: '80%' }}>
        {items.map((item, index) => (
            <ListItem key={item.name} disablePadding sx={{ display: 'block', color: '#fafafa' }}>
                <Link to={item.link} style={{ textDecoration: 'none', color: '#fafafa' }} >
                    <ListItemButton
                        sx={{
                            minHeight: 48,
                            justifyContent: open ? 'initial' : 'center',
                            px: 2.5,
                        }}
                    >
                    <ListItemIcon
                        sx={{
                            minWidth: 0,
                            mr: open ? 3 : 'auto',
                            justifyContent: 'center',
                            color: '#FAFAFA',
                        }}
                    >
                        {item.icon}
                    </ListItemIcon>
                    <ListItemText primary={item.name} sx={{ opacity: open ? 1 : 0 }} />
                    </ListItemButton>
                </Link>
            </ListItem>
        ))}
    </List>
  );
}