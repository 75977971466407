import React, { useState, useContext, useCallback } from 'react';
import { TextField, Grid, Container, IconButton, InputAdornment, Backdrop, CircularProgress, Button } from '@mui/material';
import axios from 'axios';
import SearchIcon from '@mui/icons-material/Search';
import UpdateIcon from '@mui/icons-material/Update';
import { AuthContext } from '../../../contexts/auth';

const formatCNPJ = value => {
  value = value.replace(/\D/g, '');
  const cnpjPattern = /(\d{0,2})(\d{0,3})(\d{0,3})(\d{0,4})(\d{0,2})/;
  value = value.replace(cnpjPattern, (_, p1, p2, p3, p4, p5) => {
    let formattedValue = '';
    if (p1) formattedValue += p1;
    if (p2) formattedValue += '.' + p2;
    if (p3) formattedValue += '.' + p3;
    if (p4) formattedValue += '/' + p4;
    if (p5) formattedValue += '-' + p5;
    return formattedValue;
  });
  return value.slice(0, 18);
};

const formatTelefone = telefone => {
  const telPattern = /(\d{0,2})(\d{0,1})(\d{0,8})/;
  return telefone.replace(telPattern, (match, p1, p2, p3) => {
      let formattedValue = '';
      if (p1) formattedValue += p1;
      if (p2) formattedValue += ' ' + p2;
      if (p3) formattedValue += ' ' + p3;
      return formattedValue;
  });
};

const HOST = process.env.REACT_APP_HOST;

function SearchContratante() {
  const { token, loading, toggleLoading } = useContext(AuthContext);

  const [data, setData] = useState({
    razao_social: '',
    cnpj: '',
    e_mail: '',
    endereco: '',
    cep: '',
    cidade: '',
    estado: '',
    bairro: '',
    telefone: ''
  });

  const handleSearchCNPJ = useCallback(async () => {
    toggleLoading(true);
    try {
      const apiUrl = `https://${HOST}/api/admin/contratante`;
      const response = await axios.get(apiUrl, {
        headers: { 
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + token
       },
        params: { cnpj: data.cnpj }
      });

      if (response.status === 200) {
        setData(prevData => ({
            ...prevData,
            ...response.data.contratante,
            telefone: formatTelefone(response.data.contratante.telefone)
        }));
      } else {
        alert('Erro ao buscar dados');
      }
    } catch (error) {
      alert('Erro ao buscar dados');
      console.error(`Erro ao buscar dados pelo CNPJ`, error);
    } finally {
      toggleLoading(false);
    }
  }, [data.cnpj, toggleLoading]);

  const updateData = async () => {
    toggleLoading(true);
    try {
      const apiUrl = `https://${HOST}/api/admin/contratante`;

      const dataSend = {
        cnpj: data.cnpj.replace(/\s+/g, ''),
        telefone: data.telefone.replace(/\s+/g, ''),
        e_mail: data.e_mail
      };

      const response = await axios.put(apiUrl, dataSend, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + token
        }
      });
  
      toggleLoading(false);
      if (response.status === 200) {
        alert('Dados atualizados com sucesso!');
      } else {
        alert('Erro ao atualizar dados');
      }
    } catch (error) {
      toggleLoading(false);
      alert('Erro ao atualizar dados');
      console.error("Erro ao atualizar os dados do beneficiário", error);
    }
  };

  const handleChange = (e) => {
    let { name, value } = e.target;

    if (name !== "e_mail") {
      value = value.replace(/\D/g, '');
    }

    if (name === "cnpj") {
      value = formatCNPJ(value);
      if (value.length > 18) {
          value = value.slice(0, 18);
      }
  } else if (name === "telefone") {
      value = formatTelefone(value);
      if (value.length > 13) {
          value = value.slice(0, 13);
      }
  } 

    setData(prevData => ({
      ...prevData,
      [name]: value
    }));
  };

  const renderTextField = (label, name, isEditable = false) => (
    <TextField
      fullWidth
      label={label}
      name={name}
      value={data[name]}
      onChange={handleChange}
      InputProps={{ readOnly: !isEditable }}
    />
  );

  return (
    <Container style={{ height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
      <Grid container spacing={3} alignItems="center" justifyContent="center">
        <Grid item xs={6}>{renderTextField("Razão Social", "razao_social")}</Grid>
        <Grid key={'cnpj'} item xs={6}>
          <TextField
            fullWidth
            label={'CNPJ'}
            name={'cnpj'}
            value={data['cnpj']}
            onChange={handleChange}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={handleSearchCNPJ}>
                    <SearchIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid item xs={6}>{renderTextField("Telefone", "telefone", true)}</Grid>
        <Grid item xs={6}>{renderTextField("E-Mail", "e_mail", true)}</Grid>
        <Grid item xs={6}>{renderTextField("Endereço", "endereco")}</Grid>
        <Grid item xs={6}>{renderTextField("Bairro", "bairro")}</Grid>
        <Grid item xs={5}>{renderTextField("Cidade", "cidade")}</Grid>
        <Grid item xs={5}>{renderTextField("CEP", "cep")}</Grid>
        <Grid item xs={2}>{renderTextField("Estado", "estado")}</Grid>
        <Grid item xs={12} container justifyContent="center">
          <Button 
            onClick={updateData} 
            endIcon={<UpdateIcon />}
            sx={{ bgcolor: '#008b50', color: '#fafafa', marginTop: 2, '&:hover': { bgcolor: '#006b40' }}}
            disabled={!data.cnpj || !data.telefone || data.cnpj.length < 18 || data.telefone.length < 13}
          >
            Atualizar
          </Button>
        </Grid>
      </Grid>
      <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={loading}>
          <CircularProgress color="inherit" />
      </Backdrop>
    </Container>
  );
}

export default SearchContratante;