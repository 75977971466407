import React from 'react';
import { Box, Container } from '@mui/material';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import Alert from '@mui/material/Alert';

import MOVEMENT from '../../../assets/images/BANNER1.jpg';
import ROSE from '../../../assets/images/BANNER2.jpg';
import { AuthContext } from '../../../contexts/auth';
import './index.css'

const Home = () => {
  const { open, auth } = React.useContext(AuthContext);

  const alertMessages = [
    'Participe da nossa Pesquisa de Satisfação do Beneficiário, basta clicar ',
    'Prezado contratante, se não assinou o adendo de adequação à LGPD, CLIQUE ',
    'A interface do sistema foi desenvolvida para utilização em desktops, assim, sua experiência pode ser afetada ao utilizar em dispositivos móveis.',
    'O sistema foi desenvolvido para facilitar o acesso aos dados de faturamento para os gestores da empresa e para os beneficiários.',
  ];

  const alertSeverity = ['success', 'success', 'info', 'info'];

  const banners = [MOVEMENT, ROSE];

  return (
    <div className={`${auth ? (!open ? 'autorizado' : 'sidebarOpen') : 'HomeScreen'}`}>
      <Box 
      marginLeft={1}
      marginRight={1}
      marginBottom={1}
      marginTop={auth ? 2:8}
      >
        <Carousel autoPlay infiniteLoop showThumbs={false} showStatus={false}>
          {banners.map((url, index) => (
            <div key={index}>
              <Box
                sx={{
                  backgroundImage: `url(${url})`,
                  backgroundPosition: 'center',
                  backgroundSize: 'cover',
                  backgroundRepeat: 'no-repeat',
                  color: 'white',
                  p: 4,
                  textAlign: 'center',
                  height: '500px',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              />
            </div>
          ))}
        </Carousel>
      </Box>

      <Container>
        {alertMessages.map((message, index) => (
          <Alert key={index} severity={alertSeverity[index]} sx={{ mt: 2 }}>
            {message}
            {index === 0 && (
              <a href="https://hazo.me/id_unimedcampa" target="_blank" rel="noopener noreferrer">
                AQUI.
              </a>
            )}
            {index === 1 && (
              <a href="https://forms.gle/c3Su8CeVNnAbb5V3A" target="_blank" rel="noopener noreferrer">
                AQUI.
              </a>
            )}
          </Alert>
        ))}
      </Container>
    </div>
  );
};

export default Home;