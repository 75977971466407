import React from 'react'
import { Accordion, AccordionDetails, AccordionSummary, Backdrop, CircularProgress, Divider, Stack, Typography } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import axios from 'axios';

import { AuthContext } from '../../../contexts/auth';
import DownloadButton from '../../buttons/download';

const HOST = process.env.REACT_APP_HOST;

export default function InvoiceAccordion({ faturas }) {
    const { token, loading, toggleLoading, logout } = React.useContext(AuthContext);

    const handleDownload = async (fatura, tipo) => {
        toggleLoading(true);
        try {
            const response = await axios.post(`https://${HOST}/api/v1/download/${tipo}`, 
                { fatura },
                { 
                    responseType: 'blob',
                    headers: {
                        Authorization: 'Bearer ' + token,
                    },
                });

            if (response.data.message && response.data.message === 'Token provided is invalid') {
                alert('Sessão expirada, faça login novamente');
                logout();
                window.location.reload();
                return;
            }
            
            const downloadUrl = URL.createObjectURL(new Blob([response.data]));

            const link = document.createElement('a');
            link.href = downloadUrl;
            link.download = `${tipo.toUpperCase()} ${fatura.competencia} - ${fatura.titulo}.pdf`;
            link.click();
        
            URL.revokeObjectURL(downloadUrl);
        } catch (error) {
            toggleLoading(false);
            console.error('Error downloading the invoice:', error);
        }
        toggleLoading(false);
    };

    const handleDownloadFatura = async (fatura) => {
        await handleDownload(fatura, 'fatura');
    };
    
    const handleDownloadDemonstrativo = async (fatura) => {
        await handleDownload(fatura, 'demonstrativo');
    };

    return (
        !faturas.demonstrativos ?
            <Typography sx={{ width: '100%', textAlign:'center'}}>
                <b> Nenhuma fatura encontrada </b>
            </Typography>
        :
        faturas.demonstrativos.map((fatura, index) => {
            return (
                <Accordion key={index} sx={{width: '80%'}}>
                    <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    >
                        <Typography sx={{ width: '100%', textAlign:'start', marginLeft:'2%'}}>
                            <b> TÍTULO - {fatura.titulo} </b>
                        </Typography>
                    </AccordionSummary>
                    <Divider sx={{ width: '90%', marginRight:'5%', marginBottom:'5px' }} variant="middle" />
                    <AccordionDetails>
                        <Stack direction="row" spacing={2}>
                            <Typography sx={{ width: '80%', textAlign:'start', marginLeft:'5%' }}>
                                <b>Competência</b> &nbsp;: &nbsp; {fatura.competencia} <br/>
                                <b>Valor</b> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                &nbsp;&nbsp;&nbsp;&nbsp; : &nbsp;&nbsp;R$ {fatura.valor.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })} <br/>
                                <b>Vencimento</b> &nbsp;&nbsp; : &nbsp; {fatura.vencimento} <br/>
                            </Typography>
                            
                            <DownloadButton 
                                onClick={() => handleDownloadFatura(fatura)}
                                label="DFAT"
                            />
                            <DownloadButton 
                                onClick={() => handleDownloadDemonstrativo(fatura)}
                                label="DDEM"
                            />
                        </Stack>
                    </AccordionDetails>
                <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={loading}
                >
                    <CircularProgress color="inherit" />
                </Backdrop>
                </Accordion>
            )
        })
    );
}