import React from 'react'
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import IconButton from '@mui/material/IconButton';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';

import { AuthContext } from '../../../contexts/auth';
import { Backdrop, CircularProgress } from '@mui/material';

const HOST = process.env.REACT_APP_HOST;

export default function RemoveLaunches() {
  const { token, loading, toggleLoading, logout } = React.useContext(AuthContext);
  const [rowState, setRowState] = React.useState([]);

  const columns = [
    { id: 'lancamento', label: 'LANÇAMENTO', minWidth: 100, align: 'center' },
    { id: 'data', label: 'DATA', minWidth: 100, align: 'center' },
    { id: 'hora', label: 'HORA', minWidth: 100, align: 'center' },
    { id: 'acao', label: 'AÇÃO', minWidth: 100, align: 'center' },
  ];
  
  function convertDateFormat(inputDate) {
    const splitDate = inputDate.split("/");
    if(splitDate.count === 0) {
      return null;
    }
  
    const year = splitDate[2];
    const month = splitDate[1];
    const day = splitDate[0];
  
    return year + "/" + month + "/" + day;
  }

  React.useEffect(() => {
    fetch(`https://${HOST}/api/admin/events`, {
      method: 'GET',
      headers: {
        'Authorization': 'Bearer ' + token,
      },
    })
      .then(response => response.json())
      .then((data) => {
        const participacao = typeof data.participacao === 'string' ? [] :
        data.participacao.map((participacao) => {
          return {
            file: participacao.file,
            lancamento: 'Participações',
            data: participacao.date,
            hora: participacao.time
          }
        });

        const mensalidade = typeof data.mensalidade === 'string' ? [] : 
        data.mensalidade.map((mensalidade) => {
          return {
            file: mensalidade.file,
            lancamento: 'Mensalidades',
            data: mensalidade.date,
            hora: mensalidade.time
          }
        });

        const fatura = typeof data.fatura === 'string' ? [] : 
        data.fatura.map((fatura) => {
          return {
            file: fatura.file,
            lancamento: 'Faturas',
            data: fatura.date,
            hora: fatura.time
          }
        });

        const rows = [...participacao, ...mensalidade, ...fatura].sort((a, b) => {
          const dateA = convertDateFormat(a.data) + " " + a.hora;
          const dateB = convertDateFormat(b.data) + " " + b.hora;
          
          return new Date(dateB) - new Date(dateA);
        });

        setRowState(rows);
      })
  }, [token]);
  
  const handleFileDelete = async (index) => {
    toggleLoading(true);
    const file = rowState[index].file;
    const lancamento = rowState[index].lancamento;

    const endpoint = lancamento === 'Participações' ? 'participacoes' : lancamento === 'Mensalidades' ? 'mensalidades' : 'faturas';

    await fetch(`https://${HOST}/api/admin/${endpoint}`, {
      method: 'DELETE',
      headers: {
        'Authorization': 'Bearer ' + token,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ file })
    })
      .then(response => {
        toggleLoading(false);
        return response.json();
      })
      .then((data) => {
        if(data.message && data.message === 'Token provided is invalid') {
          alert('Sessão expirada, faça login novamente');
          logout();
          window.location.reload();
          return;
        }
        if(data.exec === `${lancamento} removidas com sucesso!`) {
          const newRows = [...rowState];
          newRows.splice(index, 1);
          setRowState(newRows);
        }
      }
    );
  };

  return (
    <Paper sx={{ width: '90%', overflow: 'hidden'}}>
        <TableContainer sx={{ maxHeight: 440 }}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth }}
                    sx={{ backgroundColor: '#008b50', color: '#fafafa' }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {rowState.map((row, index) => (
                <TableRow key={row.fatura}>
                  <TableCell align="center" >
                    {row.lancamento}
                  </TableCell>
                  <TableCell align="center">
                    {row.data}
                  </TableCell>
                  <TableCell align="center">
                    {row.hora}
                  </TableCell>
                  <TableCell align="center">
                  <IconButton color="secondary" aria-label="delete file" onClick={() => handleFileDelete(index)}>
                    <DeleteOutlinedIcon />
                  </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
          <CircularProgress color="inherit" />
      </Backdrop>
      </Paper>
    )
}