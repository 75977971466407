import React from 'react';
import { BrowserRouter, useLocation } from 'react-router-dom';
import { createTheme, ThemeProvider } from '@mui/material/styles';

import './App.css';
import './assets/fonts/fonts.css';
import AppRoutes from './routes/app';
import { AuthProvider, AuthContext } from './contexts/auth';
import Sidebar from './components/sidebar';
import LoginButton from './components/buttons/login';

const theme = createTheme({
  typography: {
    fontFamily: 'MyCustomFont, Arial',
  },
});

export default function App() {
  return (
    <ThemeProvider theme={theme}>
      <AuthProvider>
        <BrowserRouter>
          <AppRoutes />
          <MainComponent />
        </BrowserRouter>
      </AuthProvider>
      </ThemeProvider>
  );
}

function MainComponent() {
  const location = useLocation();
  const { auth, role } = React.useContext(AuthContext);

  return (
    <>
      <div className={'App'}>
        {!auth && role !== "admin" && location.pathname === "/" && <LoginButton />}
        {auth && role !== "admin" && role !== null && location.pathname !== "/login" && <Sidebar />}
        {auth && role === "admin" && location.pathname !== "/admin/login" && <Sidebar />}
      </div>
    </>
  );
}