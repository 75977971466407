import React from 'react'
import Avatar from '@mui/material/Avatar';
import { Stack } from '@mui/material';
import Typography from '@mui/material/Typography';

export default function UserIdentity({text}) {
  return (
    <Stack direction="row" spacing={2} sx={{color:'#FAFAFA', justifyContent:'start'}}>
        <div style={{display:'flex', flexDirection:'row', justifyContent:'center', alignItems:'center', gap:'2px'}}>
            <Avatar sx={{bgcolor: '#448aff', mr:'4px'}}> {text[0]} </Avatar>
            <Typography variant="subtitle1" noWrap sx={{color:'#FAFAFA'}}>
                {text}
            </Typography>
        </div>
    </Stack>
  )
}