import React from 'react';
import Button from '@mui/material/Button';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Box from '@mui/material/Box';

import LogoUnimed from '../../../assets/images/logo.png';

class LoginButton extends React.Component {
  render() {
    return (
      <AppBar position="fixed" color="transparent" elevation={0}>
        <Toolbar>
          <Box display="flex" justifyContent="space-between" alignItems="center" width="100%" px={2}>
            <img src={LogoUnimed} alt="Logo Unimed" style={{ maxHeight: '50px' }} />
            <Button 
              variant="contained" 
              sx={{ 
                backgroundColor: '#008b50',
                '&:hover': {
                  backgroundColor: '#009439 !important',
                  color:'#fafafa',
                  opacity: 1
                } 
              }} 
              onClick={() => window.location.href = '/login'}
            >
              Entrar
            </Button>
          </Box>
        </Toolbar>
      </AppBar>
    );
  }
}

export default LoginButton;