import * as React from 'react';
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import CorporateFareOutlinedIcon from '@mui/icons-material/CorporateFareOutlined';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';

import './index.css';
import { AuthContext } from '../../../contexts/auth';
import SearchContratante from '../searchContratante';
import SearchBeneficiario from '../searchBeneficiario';

function LinkTab(props) {
  return (
    <Tab
      component="a"
      onClick={(event) => {
        event.preventDefault();
      }}
      {...props}
    />
  );
}

export default function SearchPage() {
  const [value, setValue] = React.useState(0);
  const { auth, open } = React.useContext(AuthContext); 

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className={`${auth ? (!open ? 'autorizado' : 'sidebarOpen') : 'search'}`}>
    <Box sx={{ 
        width: '100%',
        height: '10%',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        mt: '1rem',
        mb: '2rem'
      }}>
      <Tabs value={value} 
        onChange={handleChange} 
        aria-label="search options"
        variant="scrollable"
        scrollButtons
        allowScrollButtonsMobile
      >
        <LinkTab icon={<CorporateFareOutlinedIcon />} label={<>BUSCAR<br/>CONTRATANTE</>} />
        <LinkTab icon={<PersonOutlineOutlinedIcon />} label={<>BUSCAR<br/>BENEFICIÁRIO</>} />
      </Tabs>
    </Box>
    <Box sx={{
        width: '100%',
        height: '90%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center'
      }}>
      {value === 0 && <SearchContratante />}
      {value === 1 && <SearchBeneficiario />}
    </Box>
    </div>
  );
}
