import React from 'react'
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import { Backdrop, LinearProgress, Typography } from '@mui/material';

import { AuthContext } from '../../../contexts/auth';

const HOST = process.env.REACT_APP_HOST;

const columns = [
  { id: 'arquivo', label: 'ARQUIVO', minWidth: 100, align: 'center' },
  { id: 'status', label: 'STATUS', minWidth: 100, align: 'center' },
  { id: 'acao', label: 'AÇÃO', minWidth: 100, align: 'center' },
];

const rows = [
  { arquivo: 'contratante.csv', status: 'Vazio' },
  { arquivo: 'modalidade.csv', status: 'Vazio' },
  { arquivo: 'plano.csv', status: 'Vazio' },
  { arquivo: 'prestador.csv', status: 'Vazio' },
  { arquivo: 'procedimento.csv', status: 'Vazio' },
  { arquivo: 'tipo-plano.csv', status: 'Vazio' },
  { arquivo: 'usuario.csv', status: 'Vazio' },
  { arquivo: 'mensalidade.csv', status: 'Vazio' },
  { arquivo: 'participacao.csv', status: 'Vazio' },
  { arquivo: 'FP0610Y.lst', status: 'Vazio' },
];

export default function UpdateDatabase() {
  const [rowState, setRowState] = React.useState(rows);
  const { token, logout, loading, toggleLoading } = React.useContext(AuthContext);
  const [progress, setProgress] = React.useState(0);
  const [state, setState] = React.useState('files');

  const bulkFileInputRef = React.useRef(null);
  
  const handleBulkFileUpload = (e) => {
    const files = Array.from(e.target.files);
    const updatedRows = [...rowState];

    files.forEach((file) => {
      const rowIndex = updatedRows.findIndex(row => row.arquivo === file.name);

      if(rowIndex !== -1) {
        updatedRows[rowIndex] = { ...updatedRows[rowIndex], file: file, status: 'Recebido' };
      }

      if (bulkFileInputRef.current) {
        bulkFileInputRef.current.value = '';
      }
    });

    setRowState(updatedRows);
  };

  const handleFileUpload = (index, e) => {
    const newFile = e.target.files[0];
    const newStatus = newFile.name === rowState[index].arquivo ? 'Recebido' : 'Inválido';
    setRowState(rowState.map((row, idx) => 
      idx === index ? { ...row, file: newFile, status: newStatus } : row
    ));
  };

  const handleFileDelete = (index) => {
    setRowState(rowState.map((row, idx) => 
      idx === index ? { ...row, file: null, status: 'Vazio' } : row
    ));
  };

  const allFilesReceived = rowState.every(row => row.status === 'Recebido');

  const handleSendFiles = async () => {
    setProgress(0);
    toggleLoading(true);

    const fetchData = async (url, method = 'GET', body = null) => {
        const options = {
            method,
            headers: {
                'Authorization': `Bearer ${token}`
            },
            body
        };

        const response = await fetch(url, options);
        const data = await response.json();

        if (data.message && data.message === 'Token provided is invalid') {
            handleInvalidToken();
            throw new Error('Invalid token');
        }

        return data;
    };

    const handleInvalidToken = () => {
        toggleLoading(false);
        alert('Sessão expirada! Faça login novamente.');
        logout();
        window.location.reload();
    };

    try {
        if (allFilesReceived) {
            const formData = new FormData();
            rowState.forEach((row, index) => {
                formData.append('files', row.file);
            });

            let data = await fetchData(`https://${HOST}/api/admin/update/files`, 'POST', formData);

            if(data.message && data.message === 'Token provided is invalid') {
              alert('Sessão expirada, faça login novamente');
              logout();
              window.location.reload();
              return;
            }

            if (data.exec === 'OK') {
                setProgress(2);
                setRowState(rowState.map(row => ({ ...row, file: null, status: 'Vazio' })));
            } else {
                setRowState(rowState.map(row => ({ ...row, file: null, status: 'Vazio' })));
                throw new Error('Erro ao enviar arquivos.');
            }

            const endpoints = [
                'prestadores', 'contratantes', 'modalidades', 'tipos', 'planos',
                'usuarios', 'termos', 'carteiras', 'insumos', 'participacoes', 'mensalidades', 
                'faturas'
            ];

            for (const endpoint of endpoints) {
                setState(state => `${endpoint}`);
                data = await fetchData(`https://${HOST}/api/admin/update/${endpoint}`);

                if (data.exec === 'OK') {
                    setProgress(progress => progress + 8);
                } else {
                    setRowState(rowState.map(row => ({ ...row, file: null, status: 'Vazio' })));
                    throw new Error(`Erro ao atualizar ${endpoint}.`);
                }
            }

            setState(state => 'logs');

            const response = await fetch(`https://${HOST}/api/admin/update/logs`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${token}`
                },
            });

            if(response.message && response.message === 'Token provided is invalid') {
              alert('Sessão expirada, faça login novamente');
              logout();
              window.location.reload();
              return;
            }

            const blob = await response.blob();
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = 'SGF_att_relatorio.zip';
            a.click();
            setProgress(100);

            setRowState(rowState.map(row => ({ ...row, file: null, status: 'Vazio' })));

            setTimeout(() => {
                toggleLoading(false);
                setProgress(0);
            }, 1100);
        }
    } catch (error) {
      const response = await fetch(`https://${HOST}/api/admin/update/logs`, {
          method: 'GET',
          headers: {
              'Authorization': `Bearer ${token}`
          },
        });

        if(response.message && response.message === 'Token provided is invalid') {
          alert('Sessão expirada, faça login novamente');
          logout();
          window.location.reload();
          return;
        }

        const blob = await response.blob();
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = 'SGF_att_relatorio.zip';
        a.click();

        setRowState(rowState.map(row => ({ ...row, file: null, status: 'Vazio' })));

        toggleLoading(false);
        setProgress(0);
        alert(error.message);
    }
  };

  return (
    <div style={{width: '90%'}}>
    <Paper sx={{ width: '100%', overflow: 'hidden'}}>
        <TableContainer sx={{ maxHeight: 440 }}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth }}
                    sx={{ backgroundColor: '#008b50', color: '#fafafa' }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {rowState.map((row, index) => (
                <TableRow key={row.arquivo}>
                  <TableCell align="center" style={{ color: row.file && row.file.name === row.arquivo ? '#008b50' : 'red' }}>
                    {row.arquivo}
                  </TableCell>
                  <TableCell align="center">{row.status}</TableCell>
                  <TableCell align="center">
                    {row.status === 'Vazio' ? (
                      <label>
                        <input
                          type="file"
                          style={{ display: 'none' }}
                          onChange={(e) => handleFileUpload(index, e)}
                        />
                        <IconButton color="primary" component="span">
                          <FileUploadOutlinedIcon />
                        </IconButton>
                      </label>
                    ) : (
                      <IconButton color="secondary" aria-label="delete file" onClick={() => handleFileDelete(index)}>
                        <DeleteOutlinedIcon />
                      </IconButton>
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        </Paper>
        <Box display="flex" justifyContent="center" mt={2}>
        <label>
          <input
            ref={bulkFileInputRef}
            type="file"
            style={{ display: 'none' }}
            multiple
            onChange={handleBulkFileUpload}
          />
          <Button 
            variant="contained" 
            color="primary" 
            component="span"
            sx={{ 
              backgroundColor: '#008b50', 
              color: '#fafafa', 
              mr: 2 
            }}
          >
            Selecionar Arquivos em Lote
          </Button>
        </label>
        <Button 
          variant="contained" 
          color="primary" 
          disabled={!allFilesReceived} 
          onClick={handleSendFiles} 
          sx={{ 
            backgroundColor: allFilesReceived ? '#008b50' : 'grey', 
            color: '#fafafa' 
          }}
        >
          Enviar Arquivos
        </Button>
      </Box>
      <Backdrop
        sx={{ color: '#fff', backgroundColor: 'rgba(0, 0, 0, 0.6)', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
          <div style={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <LinearProgress sx={{ width: '80%', mb: 2 }} variant="determinate" value={progress} />
          <Typography variant="body2" color="inherit">
            { state === 'files' ? 'ENVIANDO ARQUIVOS' :  state === 'logs' ? 'GERANDO RELATORIOS DE ATUALIZACAO' : `ATUALIZANDO ${state.toUpperCase()}` } 
          </Typography>
          <Typography variant="body2" color="inherit">
            { `${progress}%` } 
          </Typography>
        </div>
      </Backdrop>
    </div>
  )
}