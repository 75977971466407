import React from 'react';
import { Routes, Route } from 'react-router-dom';
import { SecureA, SecureB, SecureC, Secure } from '../secure';

import PassTwoFactorAuthPage from '../../screens/2FA_PASS';
import TwoFactorAuthPage from '../../screens/2FA';

import Home from '../../screens/client/home';
import Contact from '../../screens/client/contact';
import Invoice from '../../screens/client/invoice';
import Login from '../../screens/client/login';
import Report from '../../screens/client/report';

import AdminLogin from '../../screens/admin/login';
import Search from '../../screens/admin/search';
import Maintenance from '../../screens/admin/maintenance';
import TokenManager from '../../screens/admin/tokenManager';

export default function AppRoutes() {
  return (
    <Routes>
        <Route path="/2fa" element={<Secure> <TwoFactorAuthPage /> </Secure>} />
        <Route path="/2fa-password" element={<Secure> <PassTwoFactorAuthPage /> </Secure>} />

        <Route path="/" element={<Home />} />
        <Route path="/login" element={<Login />} />
        <Route path="/contact" element={<Secure> <Contact /> </Secure>} />
        <Route path="/invoice" element={<SecureC> <Invoice /> </SecureC>} />
        <Route path="/report" element={<SecureB> <Report /> </SecureB>} />

        <Route path="/admin/login" element={<AdminLogin />} />
        <Route path="/admin/search" element={<SecureA><Search /></SecureA>}/>
        <Route path="/admin/maintenance" element={<SecureA> <Maintenance /> </SecureA>}/>
        <Route path="/admin/token-manager" element={<SecureA><TokenManager /></SecureA>}/>
    </Routes>
  )
}