import * as React from 'react';
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import DeleteSweepOutlinedIcon from '@mui/icons-material/DeleteSweepOutlined';
import CloudUploadOutlinedIcon from '@mui/icons-material/CloudUploadOutlined';
import PersonAddAlt1OutlinedIcon from '@mui/icons-material/PersonAddAlt1Outlined';

import './index.css';
import { AuthContext } from '../../../contexts/auth';
import RemoveLaunches from '../remove';
import UpdateDatabase from '../update'
import AddAdmin from '../addAdmin'

function LinkTab(props) {
  return (
    <Tab
      component="a"
      onClick={(event) => {
        event.preventDefault();
      }}
      {...props}
    />
  );
}

export default function MaintenancePage() {
  const [value, setValue] = React.useState(0);
  const { auth, open } = React.useContext(AuthContext); 

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className={`${auth ? (!open ? 'autorizado' : 'sidebarOpen') : 'maintenance'}`}>
    <Box sx={{ 
        width: '100%',
        height: '10%',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        mt: '1rem',
        mb: '2rem'
      }}>
      <Tabs value={value} 
        onChange={handleChange} 
        aria-label="maintenance options"
        variant="scrollable"
        scrollButtons
        allowScrollButtonsMobile
      >
        <LinkTab icon={<PersonAddAlt1OutlinedIcon />} label={<>ADICIONAR<br/>ADMINISTRADOR</>} />
        <LinkTab icon={<CloudUploadOutlinedIcon />} label={<>ATUALIZAR <br/> BANCO DE DADOS</>} />
        <LinkTab icon={<DeleteSweepOutlinedIcon />} label={<>REMOVER ÚLTIMOS<br/>LANÇAMENTOS</>} />
      </Tabs>
    </Box>
    <Box sx={{
        width: '100%',
        height: '90%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center'
      }}>
      {value === 0 && <AddAdmin />}
      {value === 1 && <UpdateDatabase />}
      {value === 2 && <RemoveLaunches />}
    </Box>
    </div>
  );
}
