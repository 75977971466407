import React, { useState } from 'react';
import {
  Box,
  TextField,
  ButtonBase,
  IconButton,
  InputAdornment,
  FormControl,
  InputLabel,
  OutlinedInput,
  Typography,
  Grid,
  Select,
  MenuItem,
  Backdrop,
  CircularProgress,
} from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';

import { AuthContext } from '../../../contexts/auth';

const HOST = process.env.REACT_APP_HOST;

const passwordRequirements = [
  { label: 'Pelo menos 8 caracteres', isValid: (password) => password.length >= 8 },
  { label: 'Pelo menos 1 letra maiúscula', isValid: (password) => /[A-Z]/.test(password) },
  { label: 'Pelo menos 1 letra minúscula', isValid: (password) => /[a-z]/.test(password) },
  { label: 'Pelo menos 1 número', isValid: (password) => /\d/.test(password) },
  { label: 'Pelo menos 1 caractere especial', isValid: (password) => /[!@#$%^&*()_+{}[\]:;<>,.?~\\/-]/.test(password) }
];

const cargoOptions = [
  'Analista de TI',
  'Assistente de TI',
  'Auxiliar Operacional',
  'Auxiliar de TI',
  'Estagiário de TI',
  'Técnico de TI',
];

export default function AddAdmin() {
  const { token, logout, loading, toggleLoading } = React.useContext(AuthContext);

  const [showPassword, setShowPassword] = useState(false);
  const [password, setPassword] = useState('');
  const [login, setLogin] = useState('');
  const [nome, setNome] = useState('');
  const [cargo, setCargo] = useState('');

  const handleCreateAdmin = async () => {
    toggleLoading(true);

    try {
      const response = await fetch(`https://${HOST}/api/admin/create`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          login,
          senha: password,
          nome: `${nome.trim()}`,
          cargo,
        }),
      });

      toggleLoading(false);

      const data = await response.json();

      if (data.message && data.message === 'Token provided is invalid') {
        alert('Sessão expirada, faça login novamente');
        logout();
        window.location.reload();
        return;
      } else {
        if (data.exec === 'OK'){
            alert('Administrador criado com sucesso');
        } else {
            alert('Erro ao criar administrador - ' + data.error);
        }
      }
    } catch (error) {
      toggleLoading(false);
      alert('Erro ao criar administrador');
    }
  };

  const handleTogglePassword = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  const handlePasswordChange = (event) => {
    const newPassword = event.target.value;
    setPassword(newPassword);
  };

  const handleNameChange = (event) => {
    const name = event.target.value;
    const formattedName = name
      .toLowerCase()
      .split(' ')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
    setNome(formattedName);
  };

  const handleLoginChange = (event) => {
    const newLogin = event.target.value;
    setLogin(newLogin);
  };

  const areAllFieldsFilled = () => nome !== '' && login !== '' && password !== '' && cargo !== '';

  const isPasswordValid = () => passwordRequirements.every((requirement) => requirement.isValid(password));

  return (
    <Box display="flex" flexDirection="column" alignItems="center" width="80%">
      <TextField
        id="Nome"
        label="Nome"
        variant="outlined"
        style={{ marginBottom: 16 }}
        fullWidth
        value={nome}
        onChange={handleNameChange}
      />
      <FormControl variant="outlined" fullWidth style={{ marginBottom: 16 }}>
        <InputLabel id="CargoLabel">Cargo</InputLabel>
        <Select
          labelId="CargoLabel"
          id="Cargo"
          value={cargo}
          onChange={(event) => setCargo(event.target.value)}
          label="Cargo"
        >
          {cargoOptions.map((option, index) => (
            <MenuItem key={index} value={option}>
              {option}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <TextField
        id="Login"
        label="Login"
        variant="outlined"
        style={{ marginBottom: 16 }}
        fullWidth
        inputProps={{ minLength: 5 }}
        value={login}
        onChange={handleLoginChange}
      />
      <FormControl variant="outlined" fullWidth>
        <InputLabel htmlFor="Senha">Senha</InputLabel>
        <OutlinedInput
          id="Senha"
          type={showPassword ? 'text' : 'password'}
          value={password}
          onChange={handlePasswordChange}
          endAdornment={
            <InputAdornment position="end">
              <IconButton onClick={handleTogglePassword} edge="end">
                {showPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          }
          label="Senha"
          inputProps={{ maxLength: 16 }}
        />
        <Grid container alignItems="flex-start" justifyContent="flex-start" mt={1}>
          <Typography variant="caption">Requisitos da senha:</Typography>
          {passwordRequirements.map((requirement, index) => (
            <Grid item xs={12} key={index}>
              <Typography
                variant="caption"
                color={requirement.isValid(password) ? 'textSecondary' : 'error'}
                sx={{ display: 'block', marginLeft: '8px' }}
              >
                {requirement.isValid(password) ? '✓' : '✗'} {requirement.label}
              </Typography>
            </Grid>
          ))}
        </Grid>
      </FormControl>
      <ButtonBase
        disabled={!isPasswordValid() || login.length < 5 || !areAllFieldsFilled()}
        sx={{
          display: 'block',
          margin: '10px auto',
          backgroundColor: isPasswordValid() && login.length >= 5 && areAllFieldsFilled() ? '#008b50' : 'grey',
          color: '#fafafa',
          padding: '10px 20px',
          borderRadius: '5px',
          '&:hover': {
            backgroundColor: isPasswordValid() && login.length >= 5 && areAllFieldsFilled() ? '#009439' : 'grey',
            opacity: 1,
          },
        }}
        onClick={handleCreateAdmin}
      >
        CADASTRAR
      </ButtonBase>
      <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </Box>
  );
}