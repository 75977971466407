import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Button, Container, CssBaseline, TextField, ThemeProvider, Typography, createTheme, Backdrop, CircularProgress } from '@mui/material';

import { AuthContext } from '../../../contexts/auth';
import UnimedLabel from '../../../components/labels/unimed';
import SwitchButton from '../../../components/buttons/switch';
import '../../../assets/fonts/fonts.css';

const HOST = process.env.REACT_APP_HOST;

export default function LoginPage() {
  const { setAuth, loading, toggleLoading } = React.useContext(AuthContext);
  const [isContratante, setIsContratante] = React.useState(false);
  const [user, setUser] = React.useState('');
  const [password, setPassword] = React.useState('');
  
  const navigate = useNavigate();

  const handleSwitchChange = () => {
    setIsContratante(!isContratante);
    setUser('');
  };

  const handleUserChange = (event) => {
    let value = event.target.value;

    if (isContratante) {
      value = value.replace(/\D/g, '');
      const cnpjPattern = /(\d{0,2})(\d{0,3})(\d{0,3})(\d{0,4})(\d{0,2})/;
      value = value.replace(cnpjPattern, (match, p1, p2, p3, p4, p5) => {
        let formattedValue = '';
        if (p1) formattedValue += p1;
        if (p2) formattedValue += '.' + p2;
        if (p3) formattedValue += '.' + p3;
        if (p4) formattedValue += '/' + p4;
        if (p5) formattedValue += '-' + p5;
        return formattedValue;
      });

      if (value.length > 18) {
        value = value.slice(0, 18);
      }
    } else {
      value = value.replace(/\D/g, '');
      const carteiraPattern = /(\d{0,1})(\d{0,3})(\d{0,12})(\d{0,1})/;
      value = value.replace(carteiraPattern, (match, p1, p2, p3, p4) => {
        let formattedValue = '';
        if (p1) formattedValue += p1;
        if (p2) formattedValue += ' ' + p2;
        if (p3) formattedValue += ' ' + p3;
        if (p4) formattedValue += ' ' + p4;
        return formattedValue;
      });

      if (value.length > 20) {
        value = value.slice(0, 20);
      }
    }

    setUser(value);
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    toggleLoading(true);

    if (isContratante && user.length !== 18) {
      alert('CNPJ inválido!');
      toggleLoading(false);
      return;
    }

    if (!isContratante) {
      const cart = user.replace(/\s/g, '');
      if (cart.length !== 17) {
        alert('CNPJ inválido!');
        toggleLoading(false);
        return;
      }
    }

    if (isContratante){
    try {
      const answer = await fetch(`https://${HOST}/api/authenticate/contratante`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ cnpj: user, senha: password }),
      });

      const response = await answer.json();

      toggleLoading(false);

      if (answer.ok && response.exec === "OK") {
        localStorage.setItem('carteira', '');

        localStorage.setItem('cnpj', user);

        localStorage.setItem('tel', response.tel);

        setAuth(true);
        navigate('/2fa');
      } else if (answer.ok && response.exec === "FIRST") {
        localStorage.setItem('carteira', '');

        localStorage.setItem('cnpj', user);

        localStorage.setItem('tel', response.tel);

        setAuth(true);
        navigate('/2fa-password');
      } else if (answer.status === 400 && response.exec === "EXISTS") {
        localStorage.setItem('carteira', '');

        localStorage.setItem('cnpj', user);

        localStorage.setItem('tel', response.tel);

        setAuth(true);

        if (response.access)
        {
          navigate('/2fa');
        } else
        {
          navigate('/2fa-password');
        }
      } else if (answer.status === 400 && response.exec === "ERROR-FONE") {
        alert(response.message.toUpperCase());
      }
      else {
        alert('Login ou senha inválidos!');
      }
    } catch (error) {
      toggleLoading(false);
      console.error('Erro ao autenticar:', error);
      alert('Ocorreu um erro ao autenticar. Por favor, tente novamente.');
    }
  } else {
    try {
      const user_verified = user.replace(/\s/g, '');
      const answer = await fetch(`https://${HOST}/api/authenticate/beneficiario`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ 
          carteira: user_verified, senha: password }),
      });

      const response = await answer.json();

      toggleLoading(false);

      if (answer.ok && response.exec === "OK") {
        localStorage.setItem('cnpj', '');
        
        localStorage.setItem('carteira', user_verified);

        localStorage.setItem('tel', response.tel);

        setAuth(true);
        navigate('/2fa');
      } else if (answer.ok && response.exec === "FIRST") {
        localStorage.setItem('cnpj', '');

        localStorage.setItem('carteira', user_verified);

        localStorage.setItem('tel', response.tel);

        setAuth(true);
        navigate('/2fa-password');
      } else if (answer.status === 400 && response.exec === "EXISTS") {
        localStorage.setItem('cnpj', '');

        localStorage.setItem('carteira', user_verified);

        localStorage.setItem('tel', response.tel);

        setAuth(true);

        if (response.access)
        {
          navigate('/2fa');
        } else
        {
          navigate('/2fa-password');
        }
      } else if (answer.status === 400 && response.exec === "ERROR-FONE") {
        alert(response.message.toUpperCase());
      }
      else {
        alert('Login ou senha inválidos!');
      }
    }
    catch (error) {
      toggleLoading(false);
      console.error('Erro ao autenticar:', error);
      alert('Ocorreu um erro ao autenticar. Por favor, tente novamente.');
    }
  }
  };

  const theme = createTheme({
    typography: {
      fontFamily: 'MyCustomFont, Arial',
    },
  });

  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '85vh',
          }}
        >
          <Box
            sx={{
              width: '100%',
              maxWidth: 400,
              boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.2)',
              borderRadius: 8,
              p: 3,
              justifyContent: 'center',
              textAlign: 'center',
            }}
          >
            <Box sx={{ display: 'flex', alignItems: 'center', mb: 1, mt: 1, flexDirection: 'column' }}>
              <Typography variant="h5" sx={{ color: 'rgba(0, 139, 80, 0.65)', mr: 1, mb: 2 }}>
                SGF - Login
              </Typography>
              <SwitchButton onChange={handleSwitchChange} />
            </Box>
            <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
              <TextField
                margin="normal"
                required
                fullWidth
                id="user"
                label={isContratante ? 'CNPJ' : 'Carteira'}
                name="user"
                autoComplete="off"
                autoFocus
                value={user}
                onChange={handleUserChange}
              />
              <TextField
                margin="normal"
                required
                fullWidth
                name="password"
                label="Senha"
                type="password"
                id="password"
                autoComplete="current-password"
                value={password}
                onChange={handlePasswordChange}
              />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2, bgcolor: '#008b50', color: '#fafafa' }}
              >
                Entrar
              </Button>
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            position: 'fixed',
            bottom: 0,
            left: 0,
            width: '100%',
            height: '10%',
            bgcolor: '#008b50',
            color: '#fafafa',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            padding: '0 16px',
            zIndex: 1,
          }}
        >
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <UnimedLabel h={'24px'} w={'24px'} ml={'5px'}/>
          </Box>
          <Typography variant="h6" sx={{ mr: 2, textAlign:'end' }}>
            Região da <br/>Campanha/RS
          </Typography>
        </Box>
        <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={loading}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </Container>
    </ThemeProvider>
  );
}